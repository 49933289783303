<template>
  <v-dialog :value="open" @input="close" width="60%">
    <v-card width="100%">
      <v-card-title>
        <span>{{ $t('popups.simulations.title') }}</span>
        <v-spacer />
        <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <div class="d-flex justify-center flex-wrap width:100%">
        <label for="simulationstartInput">{{ $t('general.start') }}</label>
        <input
          class="ma-5"
          type="datetime-local"
          id="simulationstartInput"
          name="simulation start date"
          :value="simStart"
          @change="setStartTime"
        />
        <label for="simulationEndInput">{{ $t('general.end') }}</label>
        <input
          class="ma-5"
          type="datetime-local"
          id="simulationEndInput"
          :value="simEnd"
          name="simulation end date"
          @change="setEndTime"
        />
      </div>
      <div class="d-flex justify-center flex-wrap width:100%">
        <v-btn
          :disabled="simulationButtonDisabled"
          color="primary"
          @click="startSimulation"
          :loading="isLoading"
        >
        {{ $t('popups.simulations.btn_start') }}
        </v-btn>
      </div>
      <div class="d-flex justify-end">
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              class="mr-5 mb-5"
              color="white"
              @click="downloadData"
              :loading="isLoading"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-download-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>Download Year</span>
        </v-tooltip> -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              class="mr-5 mb-5"
              color="white"
              @click="loadSimulations"
              :loading="isLoading"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('general.reload') }}</span>
        </v-tooltip>
      </div>
      <div
        class="d-flex flex-column align-center flex-wrap width:100% mx-5"
        style="min-height: 150px; font-size: 1em"
      >
        <v-data-table
          style="width: 95%; margin: 10px; font-size: 1.2em"
          dense
          :headers="simHeaders"
          :items="simulations"
          :items-per-page="10"
          class="elevation-2"
        >
          <template v-slot:[`item.state.statename`]="{ item }">
            <!-- <v-card-text :color="getStateColor(item.state.statename)">{{item.state.statename}}</v-card-text> -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-icon v-if="item.state.statename == 'ERROR'" color="error">
                    mdi-alert-circle-outline
                  </v-icon>
                  <v-icon v-if="item.state.statename == 'SUCCESS'" color="green">
                    mdi-check-bold
                  </v-icon>
                  <v-icon
                    v-if="item.state.statename == 'ACCEPTED'"
                    color="yellow">
                    mdi-alert-circle-outline
                  </v-icon>
                  <v-icon v-if="item.state.statename == 'RUNNING'" color="blue">
                    mdi-alert-circle-outline
                  </v-icon>
                  <v-icon v-if="item.state.statename == 'CANCELED'" color="error">
                    mdi-cancel
                  </v-icon>
                </div>
              </template>
              <span>{{ $t('simulation_state.' + item.state.statename) }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.date_time_start`]="{ item }">
            {{ formatDate(item.date_time_start) }}
          </template>
          <template v-slot:[`item.date_time_end`]="{ item }">
            {{ formatDate(item.date_time_end) }}
          </template>
          <template v-slot:[`item.selected_start_date`]="{ item }">
            {{ formatDate(item.selected_start_date) }}
          </template>
          <template v-slot:[`item.selected_end_date`]="{ item }">
            {{ formatDate(item.selected_end_date) }}
          </template>
          <!-- <template v-slot:item.actions="{ item }"> -->
          <template v-slot:[`item.status_message`]="{ item }">
            <p v-if="item.state.id != 4" style="font-size: 8pt">
              {{ getMessage(item.status_message) }}
            </p>
            <v-progress-linear
              v-else
              color="primary"
              height="20"
              :value="getProgressPercentage(item.status_message)"
            >
              <strong style="font-size: 0.8em">{{
                getRemainingtime(item.status_message)
              }}</strong>
            </v-progress-linear>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <!-- <v-btn
              v-if="canUpdateState(item)"
              color="primary"
              icon
              @click="() => updateSimulationState(item)"
              :title="$t('general.refresh')"
              ><v-icon>mdi-refresh</v-icon></v-btn
            > -->
            <v-tooltip
              top
              v-if="wasSuccessful(item) && isLatestSimulation(item)"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn v-if="simulationData"
                    color="primary"
                    icon
                    @click="() => saveSimulationData(item.id)"
                    ><v-icon>mdi-content-save</v-icon></v-btn>
                  <v-btn v-else
                    color="primary"
                    icon
                    @click="() => downloadData(item.id)"
                    ><v-icon>mdi-download-circle-outline</v-icon></v-btn>
                </div>
              </template>
              <span>{{ $t('popups.simulations.btn_download_data') }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SimulationPopup",
  components: {},
  data() {
    const now = this.$moment().year(2024)
    const simStart = now.clone().startOf("day").format("YYYY-MM-DD\\THH:mm")
    const simEnd = now.clone().endOf("day").format("YYYY-MM-DD\\THH:mm")
    return {
      simStart,
      simEnd
    };
  },
  computed: {
    simHeaders() {
      return [
        { text: "Sim ID", value: "id", width: "10%" },
        { text: this.$t('popups.simulations.state'), value: "state.statename" },
        { text: this.$t('popups.simulations.started'), value: "date_time_start" },
        { text: this.$t('popups.simulations.finished'), value: "date_time_end" },
        { text: this.$t('general.start'), value: "selected_start_date" },
        { text: this.$t('general.end'), value: "selected_end_date" },
        { text: this.$t('popups.simulations.msg'), value: "status_message", width: "20%", sortable: false },
        {
          text: this.$t('general.download'),
          value: "actions",
          align: "end",
          sortable: false,
          filterable: false,
          width: "3%",
        },
      ]
    },
    valid() {
      return this.date !== undefined && this.time !== undefined;
    },
    open() {
      return this.$store.getters["simulations/open"];
    },
    simulations() {
      const sims = this.$store.getters["simulations/simulations"];
      // console.log(sims);
      return sims;
    },
    simulationData() {
      const simData = this.$store.getters["simulations/simulationData"];
      // console.log(simData);
      return simData;
    },
    pendingSimulationCount() {
      return this.simulations.reduce((acc, cur) => {
        if (!["ERROR", "SUCCESS"].includes(cur.state.statename)) {
          return acc + 1;
        }
        return acc;
      }, 0);
    },
    simulationButtonDisabled() {
      return false;
      // return this.pendingSimulationCount > 3;
    },
    isLoading() {
      return this.$store.getters["simulations/loading"];
    },
  },
  methods: {
    close() {
      this.$store.dispatch("simulations/close");
    },
    loadSimulations() {
      this.$store.dispatch("simulations/loadSimulations");
    },
    startSimulation() {
      this.$store.dispatch("simulations/startSimulation", {
        start: this.simStart,
        end: this.simEnd,
      });
      setTimeout(() => {
        // console.log("loading simulations");
        this.loadSimulations();
      }, 5000);//https://app.asana.com/0/1204598009026333/1206416447574613/f 1 sec to 5 sec
    },
    canUpdateState(simulation) {
      return !["SUCCESS", "ERROR"].includes(simulation.state.statename);
    },
    wasSuccessful(simulation) {
      return simulation.state.statename == "SUCCESS";
    },
    isLatestSimulation(simulation) {
      const highest = this.simulations.reduce(function (prev, curr) {
        if (prev?.state.id == 5) {
          return curr.id > prev.id ? curr : prev;
        } else {
          return curr;
        }
      });

      return simulation.id == highest.id;
    },
    getMessage(messageString) {
      let result = "";
      if (messageString) {
        result = this.$t("simulationmessages." + messageString);
      }
      return result;
    },
    getProgressPercentage(messageObj) {
      let result = 0;
      if (messageObj) {
        try {
          result = Math.round((messageObj.step / messageObj.total) * 100);
        } catch (error) {
          console.log(error);
        }
      }
      if (!result) {
        result = 0;
      }
      return result;
    },
    getRemainingtime(messageObj) {
      let result = 0;
      try {
        messageObj = JSON.parse(messageObj);
      } catch (error) {
        console.log(error);
      }

      if (messageObj) {
        try {
          result = Math.round(
            messageObj.total * messageObj.avgtime -
              messageObj.step * messageObj.avgtime
          );
        } catch (error) {
          console.log(error);
        }
      }
      if (!result) {
        result = 0;
      }
      result = this.secondsToHms(result);
      return result + " left";
    },
    secondsToHms(d) {
      d = Number(d);
      let h = Math.floor(d / 3600);
      let m = Math.floor(d % 3600 / 60);
      let s = Math.floor(d % 3600 % 60);
      if(h >= 10) {
        return " > 10h";
      } else if (h >= 5) {
        return " < 10h";
      } else if(h >= 2) {
        return " < 5h";
      } else if(h >= 1) {
        return " < 2h";
      } else if(m >= 30) {
        return " < 1h";
      } else if(m >= 10) {
        return " < 30m";
      } else if(m >= 5) {
        return " < 10m";
      }

      let hDisplay = h > 0 ? h + "h " : "";
      let mDisplay = m > 0 ? m + "m " : "";
      let sDisplay = s > 0 ? s + "s" : "";
    return hDisplay + mDisplay + sDisplay;
},
    updateSimulationState(simulation) {
      this.$store.dispatch("simulations/updateSimulationState", simulation.id);
    },
    setStartTime(event) {
      // console.log(event);
      //TODO validate
      this.simStart = event.target.value;
    },
    setEndTime(event) {
      // console.log(event);
      //TODO validate
      this.simEnd = event.target.value;
    },
    formatDate(longDate) {
      if (longDate === null) return "";
      // return this.$moment(longDate, this.$moment().ISO_8601).format("YYYY MM DD HH:mm");
      return this.$moment(longDate, this.$moment().ISO_8601).format(
        "DD.MM.YYYY HH:mm"
      );
    },
    downloadData(simId) {
      this.$store.dispatch("simulations/downloadSimulation", simId);
    },
    saveSimulationData(simId) {
      this.$store.dispatch("simulations/saveSimulationData", simId);
    }
  },
  watch: {
    open(value) {
      if (value) {
        this.loadSimulations();
      }
    },
  },
};
</script>
